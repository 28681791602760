// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-faq-js": () => import("./../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-help-js": () => import("./../src/templates/help.js" /* webpackChunkName: "component---src-templates-help-js" */),
  "component---src-templates-how-it-works-js": () => import("./../src/templates/how-it-works.js" /* webpackChunkName: "component---src-templates-how-it-works-js" */),
  "component---src-templates-includes-js": () => import("./../src/templates/includes.js" /* webpackChunkName: "component---src-templates-includes-js" */),
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-live-code-review-js": () => import("./../src/templates/live-code-review.js" /* webpackChunkName: "component---src-templates-live-code-review-js" */),
  "component---src-templates-pricing-js": () => import("./../src/templates/pricing.js" /* webpackChunkName: "component---src-templates-pricing-js" */),
  "component---src-templates-teaching-js": () => import("./../src/templates/teaching.js" /* webpackChunkName: "component---src-templates-teaching-js" */),
  "component---src-templates-why-worth-js": () => import("./../src/templates/why-worth.js" /* webpackChunkName: "component---src-templates-why-worth-js" */)
}

