import React from 'react'
import { Provider } from 'react-redux'
import store from '@stores'


// import './styles/mixins.scss'
// import './styles/anim.scss'
// import './styles/icons.css'

const WindowCtx = React.createContext()

class Wrapper extends React.Component {

	timer = null

	state = {
		window: {
			height: 0,
			width: 0,
		},
		browser: {
			height: 0,
			width: 0
		},
		scroll: { x: 0, y: 0 },
		scrolling:false
	}

	componentDidMount() {
		this.setState({
			window: {
				height: window.innerHeight,
				width: window.innerWidth,
			},
			browser: {
				height: window.outerHeight,
				width: window.outerWidth,
			},
			scroll: {
				y: window.scrollY,
				x: window.scrollX,
			},
		})
		window.onscroll = () => {
			this.setState({
				scroll: {
					y: window.scrollY,
					x: window.scrollX,
				},
				scrolling: true
			})

			this.timer = setTimeout(()=>{
				this.setState({
					scrolling:false
				})
			},1000)
		}
		window.onresize = () => {
			this.setState({
				window: {
					height: window.innerHeight,
					width: window.innerWidth,
				},
			})
		}
	}


	render() {
		return (
			<WindowCtx.Provider value={this.state}>
				{this.props.children}
			</WindowCtx.Provider>
		)
	}
}

export default ({ element }) => {
	return (
		<Wrapper>
      		<Provider store={store}>{element}</Provider>
		</Wrapper>
)}

export { WindowCtx }
