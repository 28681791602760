import wrapRootElement from './src/reduxWrapper'
import './src/styles/global.scss'

function onPreRouteUpdate ({ location, prevLocation }) {
    // console.log("Gatsby started to change location to", location.pathname, location)
    // console.log("Gatsby started to change location from", prevLocation ? prevLocation.pathname : null)
    // LocationChangeEmitter.emit('onRouteUpdate', false)
}

function onRouteUpdate ({ location, prevLocation }){
    // console.log('new pathname', location.pathname, location)
    // console.log('old pathname', prevLocation ? prevLocation.pathname : null)
    // LocationChangeEmitter.emit('onRouteUpdate', true)
  }

export {wrapRootElement, onRouteUpdate, onPreRouteUpdate}

