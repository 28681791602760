import { createSlice } from '@reduxjs/toolkit'
import WC_GLOBALS from '../wp_globals/WOOCOMMERCE'

const initial_state = {
	imBusy: false,
    items:[],
    error:false,
    coupon:'',
    unit:'PLN',
    shipping:{},
    shippingRules:{},
    shippings: [],
    orderInApi:{},
    sum:{
        value:0,
        qty:0
    }
}

function getProperShippingsList(shippings, value){
    const [rangeValue] = Object.keys(shippings).filter(el=>el !== 'always' || el !== 'free')
    if(Number(rangeValue) < value){
        return [...shippings[rangeValue], ...shippings['free']]
    }
    else{
        return [...shippings['always'], ...shippings['free']]
    }
}

const { actions, reducer } = createSlice({
	name: 'Cart',
	initialState: initial_state,
	reducers: {
		addToCart(state, action) {
            let productWasInCart = false
            let actualItems = [...state.items]
            const { product_id, quantity, sale, regularPrice, price } = action.payload

            actualItems = actualItems.map((el)=>{
                if (el.product_id === product_id){
                    el.quantity += quantity
                    if(sale){
                        el.value += quantity * price
                        el.regularValue += quantity * regularPrice
                    }
                    else{
                        el.value += quantity * regularPrice
                        el.regularValue += quantity * regularPrice
                    }
                    productWasInCart = true
                }
                return el
            })

            if (productWasInCart === false){
                state.items = [...state.items, action.payload]
            }
            else{
                state.items = actualItems
            }

            const val = state.items.reduce((acc, cur)=>acc+cur.value,0)
            const qty = state.items.reduce((acc, cur)=>acc+cur.quantity,0)
            state.sum = {val,qty}
            state.shippings = getProperShippingsList(state.shippingRules, val)
        },
        removeFromCart(state, action){
            const { product_id  } = action.payload
            let newItems = [...state.items].filter(el=>el.product_id !== product_id)
            state.items = newItems

            const val = newItems.reduce((acc, cur)=>acc+cur.value,0)
            const qty = newItems.reduce((acc, cur)=>acc+cur.quantity,0)
            state.sum = { val,qty }
            state.shippings = getProperShippingsList(state.shippingRules, val)
        },
        changeProductQty(state, action){
            const {shippingRules} = state

            const { product_id, quantity } = action.payload
            if(quantity <= 0){
                
            }
            else{
                let newItems = [...state.items].map(el=>{
                    if(el.product_id == product_id){
                        el.quantity = quantity
                        if(el.sale){
                            el.value = quantity * el.price
                            el.regularValue = quantity * el.regularPrice
                        }
                        else{
                            el.value = quantity * el.regularPrice
                            el.regularValue = quantity * el.regularPrice
                        }
                    }
                    return el
                })
                state.items = newItems
                const val = newItems.reduce((acc, cur)=>acc+cur.value,0)
                const qty = newItems.reduce((acc, cur)=>acc+cur.quantity,0)
                state.sum = { val,qty }
                state.shippings = getProperShippingsList(state.shippingRules, val)
            }
        },
        initLoading(state,action){
            state.imBusy = true
        },
        setShippings(state, {payload}){
            state.shippingRules = payload

            const val = state.items.reduce((acc, cur)=>acc+cur.value,0)
            state.shippings = getProperShippingsList(payload, val)

        },
        setShipping(state,{payload}){
            state.imBusy = false
            state.shipping = payload
        },
        setOrder(state,{payload}){
            state.imBusy = false
            state.orderInApi = payload
        },
        setCoupon(state, {payload}){
            state.coupon = payload
        },
        onError(state,{payload}){
            state.imBusy = false
            state.error = payload
        },
        loadCart(state, {payload}){
            const {items, summary} = payload
            state.items = items
            state.summary = summary
            const val = state.items.reduce((acc, cur)=>acc+cur.value,0)
            const qty = state.items.reduce((acc, cur)=>acc+cur.quantity,0)
            state.sum = {val,qty}
        },
        resetCart(state){
            state.imBusy = false
            state.items = []
            state.shippings = getProperShippingsList(state.shippingRules, 0)
            state.orderInApi={}
            state.shipping={}
            state.coupon=''
            state.sum = {val:0,qty:0}
        },
	},
})

export { actions, reducer, initial_state }